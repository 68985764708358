import React from 'react';
import './LandingPage.css';
import logo from '../images/logo.jpg';

const LandingPage = () => {
  const restaurants = [
    { name: "Kahawa Branch", url: "https://www.sukariburgerkahawa.com" },
    { name: "USIU Branch", url: "https://www.sukariburgerusiu.com" }
  ];

  return (
    <div className='landing-page'>
        <div className='header'>
            <div className="container">
            <img src={logo} alt="SukariBurgers Logo" className="logo" />
            <h1>SukariBurgers Restaurants</h1>
            </div>
        </div>

        <div className="container">
            <h2>Restaurant Branches</h2>
            <p>Click on a restaurant to visit its website:</p>
            <div className="location-list">
            {restaurants.map((restaurant, index) => (
                <div className="location-item" key={index}>
                  <button
                    className="restaurant-button"
                    onClick={() => (window.location.href = restaurant.url)}
                  >
                    {restaurant.name}
                  </button>
                </div>
            ))}
            </div>
        </div>
    </div>
  );
};

export default LandingPage;
